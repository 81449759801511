<template>
  <v-col cols="12" class="content pa-0 mx-4 my-2">
    <v-card
      elevation="1"
      :class="
        itemFF.fare_type == '05' ? 'card-content-student' : 'card-content-multi'
      "
      v-on:click="clickRouteInformation"
    >
      <v-row align="center" class="ma-0 pa-0">
        <v-col class="ma-0 px-0 py-1" cols="8">
          <v-row align="center" justify="center" class="ma-0 pa-0 pt-1">
            <v-col class="d-flex justify-center pa-0 ma-0" cols="12">
              <h3 class="black--text font-weight-bold">
                {{ $t("BusTicket") }}
                <!-- {{ itemFF.valid_days }} <span style="text-transform: lowercase;">{{$t("Days")}} , {{ itemFF.max_trip }} {{$t("Trips")}} </span> -->
              </h3>
            </v-col>
          </v-row>
          <v-row align="center" class="ma-0 pa-4 pb-0 pt-0">
            <v-col class="ma-0 pa-0 text-center" cols="12">
              <p class="bth d-flex justify-center wrap-text">{{ itemFF.fare_name }}</p>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="ma-0 pa-0 pt-0">
            <v-col class="d-flex justify-center pa-0 ma-0" cols="12">
              <div class="rule-2 font-weight-bold">
                <span v-if="$i18n.locale == 'vi' && itemFF.max_trip == ''">
                  Chuyến đi không giới hạn</span
                >
                <span v-else-if="$i18n.locale == 'en' && itemFF.max_trip == ''">
                  Unlimited Trips</span
                >
                <span v-else-if="$i18n.locale == 'vi' && itemFF.max_trip != ''">
                  Tập {{ itemFF.max_trip }} vé</span
                >
                <span v-else>{{ itemFF.max_trip }} trips</span>
              </div>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="ma-0 pa-0 pt-0">
            <v-col
              class="subtitle-2 d-flex justify-center pa-0 ma-0 text-center"
              cols="12"
            >
              {{ $t("PurchaseDate") }} : {{ itemFF.success_time }}
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="ma-0 pa-0">
            <v-col
              v-if="itemFF.max_trip == ''"
              class="
                subtitle-2
                d-flex
                justify-center
                pa-0
                ma-0
                text-center text-lowercase
              "
              cols="12"
            >
              {{ itemFF.used_ticket }} {{ $t("UsedTrips") }}
            </v-col>
            <v-col
              v-else
              class="
                subtitle-2
                d-flex
                justify-center
                pa-0
                ma-0
                text-center
              "
              cols="12"
            >
              {{ $t("TicketsRemaining") }}
              {{ itemFF.max_trip - itemFF.used_ticket }}
              {{ $t("TicketsRemainingDetail") }}
            </v-col>
          </v-row>
        </v-col>
        <img class="divider" :src="require('@/assets/divider-ticket.svg')" />
        <v-col class="justify-center col-kanan pt-0 pb-0" cols="4">
          <v-row>
            <v-col class="text-center px-0 py-0 mt-n2">
              <span style="font-size: 0.60rem">{{itemFF.ticket_number}}</span>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="py-0">
              <v-btn
                v-if="$partnerACL.isShowButton('detail')"
                class="text-capitalize btn-buy-hijau"
                v-on:click="clickView"
                rounded
                outlined
                min-width="70px"
                :color="$partnerACL.getButtonColor()"
                :style="`color:${$partnerACL.getButtonColor()}`"
                small
              >
                {{ $t(viewticket) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>

export default {
  name: "FlateTicket",
  props: {
    itemFF: {
      type: Object,
    },
    tickettransaction: {
      type: String,
      default: "Ticket Transaction",
    },
    orig: {
      type: String,
      default: "BTH",
    },
    original: {
      type: String,
      default: "Bến Thành",
    },
    expticket: {
      type: String,
      default: "Single Trip ticket is valid until 24 hours",
    },
    routename: {
      type: String,
      default: "Route 3",
    },
    flatefare: {
      type: String,
      default: "Single Trip",
    },
    dest: {
      type: String,
      default: "TLC",
    },
    destination: {
      type: String,
      default: "Thạnh Lộc",
    },
    viewticket: {
      type: String,
      default: "ViewTicket",
    },
    routeInformation: {
      type: Object
    },
  },
  async created() {
    const payloadCurrency = {
      currencyCode: localStorage.getItem('currency') || "IDR",
    };
    await this.$store.dispatch("purchaseticket/getCurrencyConversionRate", payloadCurrency)
  },
  computed: {
    provider() {
      return this.$store.state.auth.provider;
    },
  },
  methods: {
    clickRouteInformation() {
      this.$store.commit("moduleUi/ROUTE_INFORMATION", this.routeInformation);
    },
    clickView() {
      // this.$emit("onClickView", this.params.value)
      if (this.provider == "GOBUS") {
        this.$router.push({
          name: "GobusDetailTicket",
          params: {
            data: this.itemFF,
          },
        });
      } else {
        this.$router.push({
          name: "ViewTicket",
          params: {
            data: this.itemFF,
          },
        });
      }
    },
    convertPriceByCurrency(price, currency){
      const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
      const priceResult = price * conversionRates[currency];
      return priceResult;
    },

  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.card-content-student {
  // width: 90%;
  border: 2px solid #53c1ff;
  margin: 0;
  border-radius: 10px;
  max-height: 25%;
}
.card-content-multi {
  // width: 90%;
  border: 2px solid #f29900;
  margin: 0;
  border-radius: 10px;
  max-height: 25%;
}
.divider {
  border: none;
  width: 2px;
  height: 110px;
}
.v-card.theme--light.v-sheet {
  // position: absolute;
  // border: 0.5px solid #c5c5c5;
  overflow: hidden;
}
.v-application .elevation-1 {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
.col-kanan {
  height: 100%;
}

.halfcircle {
  background: #ffffff;
  width: 12.1px;
  height: 22.1px;
  position: absolute;
  top: 45%;
  border: 1px solid black;
  overflow: hidden;
  z-index: 5;
}

.cut-out {
  border-radius: 50px;
  width: 100%;
  height: 20%;
  display: block;
  position: absolute;
  top: 40%;
  background: #fdfdfd;
}

.halfcircle-1 {
  left: -97%;
  box-shadow: inset -1px 2px 2px -1px #888;
}
.halfcircle-2 {
  right: -97%;
  box-shadow: inset 1px 1px 1px 1px #888;
}
.flex-grid {
  display: flex;
}
.col {
  flex: 1;
}
.crop-kiri {
  margin-right: 0%;
  height: 200px;
}
.crop-kanan {
  margin-left: -10%;
  height: 200px;
}
.card-content {
  // width: 90%;
  border: 1px solid rgba(72, 158, 75, 1);
  margin: 0;
  border-radius: 10px;
  max-height: 25%;
}
.content {
  margin-bottom: -12px;
}
.component-1 {
  float: left;
  width: 100%;
  position: relative;
}
.btn-buy-hijau {
  width: 100%;
}
.content-ticket {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: white;
  padding: 1em;
}
.background-ticket {
  display: block;
  width: 100%;
}
.route-1 {
  color: rgba(134, 134, 134, 1);
  font-size: 10px;
}
.flex-wrapper-three {
  margin-top: -10px;
  padding: 0 0 0 0px;
  display: flex;
  align-items: center;
}
.bth {
  font-size: 15px;
  font-weight: 800;
  margin: 0;
  padding: 0;
  color: rgba(72, 158, 75, 1);
}
.relative-wrapper-one {
  margin-right: 15px;
  position: relative;
}
.vector {
  margin-top: 10px;
  width: 80%;
  height: auto;
}
.group {
  position: absolute;
  left: 55px;
  top: -1px;
  transform: rotate(180deg);
}
.flex-wrapper-four {
  margin-left: 10px;
  margin-bottom: -40px;
  // padding: 0 0 0 21px;
  // display: flex;
  // align-items: flex-start;
}
.bn-thanh {
  // @include roboto-10-light;
  margin-top: -15px;
  font-size: 9px;
  color: rgba(75, 177, 78, 1);
  right: 122px;
}
.place-desc {
  font-size: 10px;
  color: rgba(75, 177, 78, 1);
  white-space: nowrap;

  margin-top: -3px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  padding-bottom: 0;
}
.vector-7 {
  width: 100%;
  margin-bottom: 9px;
}
.flex-wrapper-five {
  padding: 0 0 0 17px;
  display: flex;
  align-items: flex-start;
}
.flex-wrapper-six {
  margin-right: 43px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.route-information {
  // @include roboto-12-medium;
  font-size: 8px;
  color: rgba(94, 215, 153, 1);
  text-decoration: underline;
  margin-top: 0px;
  margin-left: 25%;
  margin-bottom: -2px;
}
.flat-fare-ticket-is-valid-for-24-hours {
  // @include roboto-8-light;
  font-size: 8px;
  margin-left: 25%;
  color: rgba(75, 177, 78, 1);
  font-style: italic;
}
.num-135000-v-nd {
  // @include roboto-14-medium;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
  color: rgba(242, 153, 0, 1);
}
.group-18785 {
  border-radius: 23px;
  height: 31px;
  width: 80px;
  padding: 5px 6px 2px 4px;
  position: relative;
  border: 1px solid rgba(75, 177, 78, 0.35);
}

.class-btn-buy {
  height: 20px;
  // border-radius: 23px;
  // height: 29px;
  // padding: 2px 6px 2px 4px;
  // border: 1px solid rgba(75, 177, 78, 0.35);
  // position: absolute;
  // width: 76px;
  // height: 29px;
  // left: 300px;
  // top: 535px;

  // background: #4BB14E;
  /* Button */

  // box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.1);
}
.flex-wrapper-one {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 41px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}
.flex-wrapper-two {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  display: flex;
  height: 30px;
  width: 30px;
  align-items: center;
  position: absolute;
  left: -10px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}
.hyphen {
  width: 5px;
  height: 2px;
  background-color: rgba(75, 177, 78, 1);
}
.label {
  width: 64px;
  // @include roboto-10-bold;
  color: rgba(75, 177, 78, 1);
  text-align: center;
  position: relative;
}
.btn-plus {
  top: -5px;
  height: 30px !important;
  width: 30px !important;
  font-size: 20px;
  border: 1px solid rgba(75, 177, 78, 1);
  z-index: 2;
  background-color: #ffffff !important;
  color: rgba(75, 177, 78, 1);
  left: 45px;
}
.btn-minus {
  top: -5px;
  height: 30px !important;
  width: 30px !important;
  background-color: #ffffff !important;
  color: rgba(75, 177, 78, 1);
  border: 1px solid rgba(75, 177, 78, 1);
  z-index: 2;
  font-size: 20px;
  left: -40px;
}
.icon-plus {
  height: 6px;
  width: 1px;
  left: -1px;
}
.icon-minus {
  height: 6px;
  width: 1px;
  left: -1px;
}
.input-label {
  // left: 4px;
  top: -30px;
}
.onHover {
  cursor: pointer;
}
.wrap-text {
  overflow-wrap: anywhere;
}
</style>