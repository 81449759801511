<template>
  <v-dialog v-model="dialog" persistent>
    <v-card class="rounded-card">
      <v-container>
        <!-- <p class="header-title mb-0" align="center">{{$t("MultiTripsInfo")}}</p> -->
        <v-row align="center" class="ma-0 pa-4 pb-0 pt-0">
          <v-col class="ma-0 px-0 py-5 d-flex text-center justify-center" cols="12">
            <h2 class="pl-1 ticket">
              <!-- {{expired_after_usage}} {{$t("DaysTicket")}} -->
              {{$t("TicketInformation")}}
            </h2>
          </v-col>
        </v-row>
        <v-row style="border-top: 1px solid #cecece;">
          <v-col>
            <b style="font-size: 16px;color: rgba(0, 0, 0, 0.8);">{{$t("TripInformation")}}</b>
            <p v-if="fareRule.max_trip == 0 & fareRule.max_trip_perday == 0" class="mb-0"> {{$t("UnlimitedTrips")}} - {{$t("UnlimitedDays")}}</p>
            <p v-else-if="fareRule.max_trip == 0 & fareRule.max_trip_perday != 0" class="mb-0"> {{$t("UnlimitedTrips")}} -   {{$t("Maximum")}} {{fareRule.max_trip_perday}} {{$t("TripPerDay")}}</p>
            <p v-else-if="fareRule.max_trip != '' & fareRule.max_trip_perday == 0" class="mb-0">  {{$t("Maximum")}} {{fareRule.max_trip}} {{$t("Trips")}} - {{$t("UnlimitedDays")}}</p>
            <p v-else class="mb-0"> {{$t("Maximum")}} {{fareRule.max_trip}} {{$t("Trips")}} - {{fareRule.max_trip_perday}} {{$t("TripPerDay")}}</p>
            <p class="mb-0">Multi day ticket is valid for {{fareRule.valid_days}} days</p>
            <p class="mb-0"> {{$t("Price")}}: {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: currencyCode || 'USD' }).format(price) }} / {{ $t("Turn") }}<br /></p>
            <p class="mb-0"> {{$t("ValidUntil")}}: {{ fareRule.validate_close_date }}</p>
                  
          </v-col>
        </v-row>
        
        <v-row class="text-left">
          <v-col class="" v-for="(route, index) in routeLoc" :key="index" cols="12">
            <span class="body-2 font-weight-bold">{{route.route_long_name}}</span>
            <p class="detail-route text-justify mb-0">
              <span v-for="(trip, index2) in route.trips" :key="index2">
                <!-- {{ trip }} <span v-if="index2!=route.trip_route.length-1" class="px-1">-</span>  -->
                <span v-for="(tripDetail, index3) in trip.trip_route" :key="index3">
                  {{ tripDetail }} 
                  <span v-if="index3!=trip.trip_route.length-1" class="px-1">-</span> 
                </span>
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row >
          <v-col v-if="expiredNote === 'ExpiredOnSameDay'" class="d-flex justify-center">
            <p class="detail-route black--text mb-0 font-weight-black">
              {{ $t("ExpiredOnSameDay") }}
            </p>
          </v-col>
          <v-col
            v-else-if="expiredNote === 'ExpiredAfterPurchase'"
            class="d-flex justify-center"
          >
            <p class="detail-route black--text mb-0 font-weight-black">
              {{ $t("ValidFor") }} {{ fareRule.valid_days }} {{ $t("Days") }}
            </p>
          </v-col>
          <v-col
            v-else-if="expiredNote === 'ExpiredAfterUsage'"
            class="d-flex justify-center"
          >
            <p class="detail-route black--text mb-0 font-weight-black">
              {{ $t("ValidFor") }} {{ fareRule.valid_days }} {{ $t("ExpiredAfterUsage") }}
            </p>
          </v-col>
          <v-col
            v-else-if="expiredNote === 'ValidationPeriod' && fareRule.validate_close_date != ''"
            class="d-flex justify-center"
          >
            <p class="detail-route black--text mb-0">
              <span class="font-weight-black">{{ $t("ValidFor") }} </span><br/>
              {{ fareRule.validate_open_date + " " }}
              {{ '(' + fareRule.validate_open_time +') ' }}
              {{ $t("to") + " " + fareRule.validate_close_date + " " }} 
              {{ fareRule.validate_close_time ? '(' + fareRule.validate_close_time + ') ' : '(23:59:59)' }}
            </p>
          </v-col>
          <v-col
            v-else-if="expiredNote === 'ValidationPeriod' && fareRule.validate_close_date == ''"
            class="d-flex justify-center"
          >
            <p class="detail-route black--text mb-0">
              <span class="font-weight-black">{{$t("ValidFrom")}} </span><br/>
              {{ " " + fareRule.validate_open_date + ", " }}
              {{ " " + fareRule.validate_open_time + " " }}
            </p>
          </v-col>
          <v-col v-else-if="expiredNote === 'Unlimited'" class="d-flex ma-0">
            <p class="detail-route black--text mb-0 font-weight-black"></p>
          </v-col>
          <v-col v-else class="d-flex ma-0">
            <p class="detail-route black--text mb-0 font-weight-black"></p>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <!-- <v-col v-if="expiredNote === 'ExpiredOnSameDay'" class="d-flex ma-0 px-5">
            <p class="detail-route black--text mb-0 font-weight-black">
              {{ValidUntil}}{{ $t("ExpiredOnSameDay") }}
            </p>
          </v-col>
          <v-col
            v-else-if="expiredNote === 'ExpiredAfterPurchase'"
            class="d-flex ma-0 px-5"
          >
            <p class="detail-route black--text mb-0 font-weight-black">
              {{ $t("ValidFor") }} {{ fareRule.valid_days }} {{ $t("Days") }}
            </p>
          </v-col>
          <v-col
            v-else-if="expiredNote === 'ValidationPeriod' && validate_close_date != ''"
            class="d-flex ma-0 px-5"
          >
            <p class="detail-route black--text mb-0">
              <span class="font-weight-black">{{ $t("ValidFor") }} </span> <br/>
              {{ " " + validateopent_date + " " }}{{ $t("to") + " " + validate_close_date + ", " }} 
              {{ " " + validate_open_time + " " }}{{ $t("to") }}
              {{ validate_closed_time + " " }}
            </p>
          </v-col>
          <v-col
            v-else-if="expiredNote === 'ValidationPeriod' && validate_close_date == ''"
            class="d-flex ma-0 px-5"
          >
            <p class="detail-route black--text mb-0">
              <span class="font-weight-black">{{ $t("ValidFrom") }} </span> <br/>
              {{ " " + fareRule.validate_open_date}},
              {{ " " + validate_open_time + " " }}{{ $t("to") }}
              {{ validate_closed_time + " " }}
            </p>
          </v-col>
          <v-col
            v-else-if="expiredNote === 'ExpiredAfterUsage'"
            class="d-flex ma-0 px-5"
          >
            <p class="detail-route black--text mb-0 font-weight-black">
              {{ $t("ValidFor") }} 
              {{ " " + fareRule.expired_after_usage + " " }} {{$t("ExpiredAfterUsage")}}
            </p> -->
          <!-- </v-col> -->
          <!-- <v-col v-else-if="expiredNote === 'Unlimited'" class="d-flex ma-0 px-5">
            <p class="detail-route black--text mb-0 font-weight-black"></p>
          </v-col>
          <v-col v-else class="d-flex ma-0 px-5">
            <p class="detail-route black--text mb-0 font-weight-black"></p>
          </v-col> -->
        </v-row>
        <div align="center">
          <v-spacer></v-spacer>
          <!-- <v-btn
            style="text-transform: capitalize;"
            rounded
            width="50px"
            height="30px"
            color="primary"
            @click="clickClose()"
          >
            {{$t("Close")}}
          </v-btn> -->
          <ac-button 
            :name="$t('Close')" 
            :on-click-handler="clickClose"
          />
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AcButton from "@/components/AcButton.vue";
export default {
  name: "RouteInformation",
  components: { AcButton },
  props: {
    routeTitle: {
      /*
        Description message
      */
      type: String,
      default: "Route 1 Bến Thành - Bến Xe buýt Chợ Lớn"
    },
    expiredNote: {
      type: String,
    },
    currencyCode: {
      type: String,
    },
    price: {
      type: Number,
    },
    fareRule: {
      type: Object,
      default: () => {
        return {
          max_trip: 0,
          valid_days: 0,
          max_trip_perday: 0,
          selling_open_date: null,
          selling_open_time: null,
          selling_close_date: null,
          selling_close_time: null,
          validate_open_date: null,
          validate_open_time: null,
          expired_after_usage: 0,
          expired_on_same_day: false,
          validate_close_date: null,
          validate_close_time: null
        }
      }
    },
    routeLoc: {
      type: Array,
      default: () => []
    },
    dialog: {
      /*
        set open dialog
      */
      type: Boolean,
      required: true,
      default: false,
    },
    btnClose: {
      type: Function,
      required: false,
    },
  },
  methods: {
    clickClose() {
      this.$store.commit("moduleUi/ROUTE_INFORMATION", "");
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 15px;
}

.header-title {
  font-family: Roboto;
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;

  color: #4bb14e;
}

.route-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: -5px;

  color: #000000;
}

.detail-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-top: -40px;
  margin-bottom: -20px;
  color: #000000;
}

.ticket {
  font-weight: bold;
  font-size: 24px;
  line-height: 118.88%;

  color: #489E4B;
}


.name-loc {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 118.88%;
  /* identical to box height, or 31px */

  text-transform: capitalize;

  color: #489e4b;
}

.name-location {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-transform: capitalize;

  color: #4bb14e;
}

.detail-route {
  font-size: 10px;
  color: #4bb14e;
  text-align: center;
}

.num-135000-v-nd {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
  color: rgba(242, 153, 0, 1);
}
</style>