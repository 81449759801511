<template>
  <div class="myticket" v-if="loadComponent" >
    <RouteInformation
      :routeTitle="itemRoute.original_loc + '-' + itemRoute.destination_loc"
      :keyItem="itemRoute.fare_id"
      :origin="itemRoute.original_loc"
      :ToOrig=itemRoute.fare_detail
      :destination="itemRoute.destination_loc"
      :ToDest=itemRoute.routeLoc
      :dialog="routeInfoDialog"
      :orig="itemRoute.orig_loc"
      :dest="itemRoute.dest_loc"
      :expiredNote="itemRoute.expired_note"
      :validate_close_date="itemRoute.validate_close_date"
      :validate_open_date="itemRoute.validate_open_date"
      :fare-rule="itemRoute.fare_rule"
      :RouteCode="itemRoute.orig_loc + ' - ' + itemRoute.dest_loc"
    /> 

    <RouteInformationMulti
      :routeTitle="itemRoute.fare_name"
      :keyItem="itemRoute.fare_id"
      :dialog="routeInfoDialogMulti"
      :expiredNote="itemRoute.expired_note"
      :fare-rule="itemRoute.fare_rule"
      :price="itemRoute.price"
      :currency-code="itemRoute.currencyCode"
      :route-loc="itemFF.fare_detail"
    />

    <v-container v-if="userRole.toUpperCase().includes('BLACKLIST')">
      <div>
        <v-row
          justify="center"
          class="null-item"
        >
          <v-col class="d-flex justify-center pb-0" cols="10">
            <v-img
              class="image"
              :src="require('../../assets/bus-homepage.svg')"
            ></v-img>
          </v-col>
          <v-col class="d-flex justify-center py-0 mt-4" cols="12">
            <h2 class="text-center">{{ $t("SuspendWarning") }}</h2>
          </v-col>
        
        </v-row>
      </div>
    </v-container>
    <v-container v-else>
      <v-row
        v-if="((itemFF.length === 0 && itemMD.length === 0 && itemSD.length === 0) || !isOnline)"
        justify="center"
        class="null-item"
      >
        <v-col class="d-flex justify-center pb-0" cols="10">
          <v-img
            class="image"
            :src="require('@/assets/icon/no-ticket.svg')"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center py-0" cols="12">
          <h2 class="text-center">{{ $t("NoTicket") }}</h2>
        </v-col>
        <v-col class="d-flex justify-center mt-3">
          <v-btn
            v-if="$partnerACL.isShowButton('purchase')"
            :color="$partnerACL.getButtonColor()"
            rounded
            width="80%"
            class="capitalize body-1"
            @click="clickPurchase()"
          >
            {{ $t("PurchaseTicket") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
        no-gutters
        v-for="item in itemFF"
        :key="item.ticket_id"
      >
        <ticketffcard
          :orig="item.orig_loc"
          :original="item.original_loc"
          :routename="item.cart_item_name"
          :expticket="item.ticket_expired_date"
          :dest="item.dest_loc"
          :destination="item.destination_loc"
          :itemFF="item"
          :routeInformation="item"
        ></ticketffcard>
      </v-row>
      <v-row
        align="center"
        justify="center"
        no-gutters
        v-for="item in itemMD"
        :key="item.ticket_id"
      >
        <ticketmdcard 
          :itemFF="item"
          :routeInformation="item"
        ></ticketmdcard>
      </v-row>
      <v-row
        align="center"
        justify="center"
        no-gutters
        v-for="item in itemSD"
        :key="item.ticket_id"
      >
        <ticketmdcard :itemFF="item"></ticketmdcard>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import store from "@/store";
import ticketffcard from "@/components/CardTicketFlate.vue";
import ticketmdcard from "@/components/CardTicketMultiDays.vue";
import moment from "moment";
import RouteInformation from "@/components/RouteInformation.vue";
import RouteInformationMulti from "@/components/RouteInformationMulti.vue";

export default {
  name: "MyTicket",
  components: { ticketffcard, ticketmdcard, RouteInformation, RouteInformationMulti },
  data() {
    return {
      notifSuccess: false,
      notifFailed: false,
      itemFF: [],
      itemMD: [],
      itemSD: [],
      psg_id: store.state.auth.userData.psg_id,
      customerId: store.state.auth.userData.customerID,
      limit: '20',
      loadComponent: false,
      isOnline: navigator.onLine,
      routeInfoDialog: false,
      routeInfoDialogMulti: false
    };
  },
  async created() {
    const payloadCurrency = {
      currencyCode: localStorage.getItem('currency') || "IDR",
    };
    await this.$store.dispatch("purchaseticket/getCurrencyConversionRate", payloadCurrency)
  },
  methods: {
    convertPriceByCurrency(price, currency){
      const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
      const priceResult = price * conversionRates[currency];
      return priceResult;
    },
    getExpiredNote(fareRule) {
      return fareRule.expired_on_same_day ? 'ExpiredOnSameDay' :
        fareRule.valid_days != null && !fareRule.expired_on_same_day ? 'ExpiredAfterPurchase' :
          fareRule.expired_after_usage ? 'ExpiredAfterUsage' :
            fareRule.validate_close_date == null ? 'Unlimited' :
              'ValidationPeriod'
    },
    clickPurchase() {
      const purchaseButton = this.$partnerACL.getMetaButton('purchase');
      if (purchaseButton) {
        if (purchaseButton.menu_type == "wsc_menu") {
          this.$router.push({
            name: purchaseButton.redirect_name,
          });
        } else {
          window.location = purchaseButton.redirect_path;
        }
      } else {
        this.$router.push({
          name: "Ticket",
        });
      }
    },
  },
  computed: {
    itemRoute() {
      return this.$store.state.moduleUi.routeInformation;
    },
    userRole() {
      return this.$store.state.auth.user_role;
    }
  },
  watch: {
    itemRoute() {
      if (this.itemRoute == "") {
        this.routeInfoDialog = false
        this.routeInfoDialogMulti = false
      } else {
        if (this.itemRoute.modal_type == "singletrip") {
          this.routeInfoDialog = true;
        } else if (this.itemRoute.modal_type == "multitrip") {
          this.routeInfoDialogMulti = true;
        }
      }
    },
  },
  async mounted() {
    const payload = {
      // psg_id: this.psg_id,
      customer_id: this.customerId,
      limit: Number(this.limit)
    };
    await this.$store
      .dispatch("myticket/flatefare", payload)
      .then((response) => {
        this.itemFF = [];
        for (let data of response) {     
          const setting_currency = localStorage.getItem('currency')
          const convertPrice = this.convertPriceByCurrency(data.price, data.currency_code)
          
          const buildFF = {
            ...data,
            price: convertPrice,
            basePrice: data.price,
            currencyCode: setting_currency,
            expired_note: this.getExpiredNote(data.fare_rule),
            validate_open_date: data.fare_rule.validate_open_date ? moment.utc(data.fare_rule.validate_open_date).format("DD-MM-YYYY") : "",
            validate_close_date: data.fare_rule.validate_close_date ? moment.utc(data.fare_rule.validate_close_date).format("DD-MM-YYYY") : "",
            modal_type: "singletrip"
          }
          
          this.itemFF.push(buildFF);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await this.$store
      .dispatch("myticket/multidays", payload)
      .then((response) => {
        this.itemMD = [];
        for (let data of response) {
          const setting_currency = localStorage.getItem('currency')
          const convertPrice = this.convertPriceByCurrency(data.price, data.currency_code)
          const created_date = moment.utc(this.itemMD.created_date).format("DD-MM-YYYY HH:mm")
          const success_time = moment.utc(data.invoice_detail[0].payment_success_time).format("DD-MM-YYYY HH:mm")
          
          const buildMD = {
            ...data,
            price: convertPrice,
            basePrice: data.price,
            expired_note: this.getExpiredNote(data.fare_rule),
            currencyCode: setting_currency,
            created_date: created_date,
            success_time: success_time,
            modal_type: "multitrip"
          }

          this.itemMD.push(buildMD);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // await this.$store
    //   .dispatch("myticket/studentfare", payload)
    //   .then((response) => {
    //     this.itemSD = [];
    //     for (let data of response) {
    //       this.itemSD.push(data);
    //       // console.log("data asa",data)
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    this.loadComponent = true
    // this.$root.$on("onClickView", data => {
    // this.viewticket(item);
    // });
    const payloadd = {
      cart_psg_id: this.psg_id,
    };
    await this.$store
      .dispatch("purchaseticket/getQtyTicket", payloadd)
      .then((response) => {
        this.itemMT = [];
        for (let data of response) {
          this.itemMT.push(data);
          console.log("Ini Data Myticket :", data);
          // console.log("data asa",data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style lang="scss" scoped>
.myticket {
  background-color: rgba(245, 255, 247, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.flex-wrapper-one {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 20px;
}

.back-img {
  margin-right: 12px;
}

.my-ticket {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: black;
  margin: 0;
}

.rounded-card {
  border-radius: 15px !important;
  background: linear-gradient(180deg, #ffffff 0%, #ffe9fc 100%);
}

.null-item {
  position: absolute;
  top: 46%;
  right: 50%;
  transform: translate(46%, -50%);
}

.capitalize {
  text-transform: capitalize;
}
</style>